import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {ToastrService} from 'ngx-toastr';
import {AuthentificationService} from '../authentification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthentificationService,
    private toastr: ToastrService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
    .pipe(catchError(err => {
      if (err.status === 401) {
        this.authenticationService.logout();
        location.reload();
      }

      if (err.status === 404) {
        this.toastr.error(err.message, 'Erreur');
        return throwError(err.message);
      }

      if (err.status === 500) {
        if (err.error instanceof Object) {
          this.toastr.error(err.error.message, 'Erreur');
        } else {
          this.toastr.error(err.error, 'Erreur');
          return throwError(err.error);
        }
      }

      if (err.status === 422) {
        for (const ERROR in err.error.errors) {
          const ERROR_MESSAGE = err.error.errors[ERROR][0];
          this.toastr.error(ERROR_MESSAGE, 'Erreur');
        }
        return throwError(err.error);
      }

      const error = err.error.message || err.statusText;
      return throwError(error);
    }));
  }
}
