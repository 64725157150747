<div class="modal-header">
    <h5 class="modal-title" id="modal-title">
        Enregistrement de documents
    </h5>
</div>
<div class="modal-body p-4">
    <div class="col-lg-12">
        <div class="card">
            <div class="media">
                <div class="media-body">
                    <p class="card-title-desc">
                        Les champs avec <code>(*)</code> sont obligatoires
                    </p>
                </div>
            </div>
            <div class="job-search">
                <div class="row mt-3" [formGroup]="documentForm">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label class="form-label">
                                Type de document <code>(*)</code>
                            </label>
                            <ng-select [items]="typeDocument$" bindLabel="libelle"
                                       bindValue="id"
                                       placeholder="Veuillez selectionner un type de document"
                                       formControlName="type"></ng-select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label>
                                Libellé <code>(*)</code>
                            </label>
                            <input type="text" class="form-control"
                                   formControlName="libelle"
                                   placeholder="Veuillez saisir le numero ou nom du document"/>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label>
                                Date d'ajout du document <code>(*)</code>
                            </label>
                            <div class="input-group clockpicker">
                                <input class="datepicker-here form-control digits"
                                       formControlName="date"
                                       placeholder="Veuillez selectionner une date"
                                       ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="mb-3">
                            <label>
                                Pièce jointe <code>(*)</code>
                            </label>
                            <app-ng-dropzone
                                             placeholder="Veuillez selectionner un document puis ajouter à la liste avant de selectionner un second"
                                             [files]="documentPieceJointe"></app-ng-dropzone>
                        </div>
                    </div>
                </div>
                <div style="text-align: end">
                    <button type="button" class="btn btn-info" (click)="valider()">
                        Valider
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
