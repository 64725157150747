<form class="needs-validation" (ngSubmit)="save()" [formGroup]="villeForm">
    <div class="modal-header">
        <h5 class="modal-title">
            Formulaire de gestion de ville
        </h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="modal-body">
        <p class="card-title-desc">
            Les champs avec <code>(*)</code> sont obligatoires
        </p>
        <div class="row">
            <div class="col-md-12">
                <div class="mb-3">
                    <label>
                        Pays <code>(*)</code>
                    </label>
                    <ng-select [items]="pays$" bindLabel="libelle" bindValue="id"
                               formControlName="pays"></ng-select>
                </div>
            </div>
            <div class="col-md-12">
                <div class="mb-3">
                    <label>
                        Libellé <code>(*)</code>
                    </label>
                    <input type="text" class="form-control" formControlName="libelle"
                           placeholder="Veuillez saisir le libellé"/>
                </div>
            </div>
            <div class="col-md-12" *ngIf="item">
                <div class="mb-3">
                    <label>
                        Statut <code>(*)</code>
                    </label>
                    <select class="form-control" formControlName="statut">
                        <option [value]="'0'">Désactiver</option>
                        <option [value]="'1'">Activer</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit">
            Valider
        </button>
    </div>
</form>
