<div class="row mt-3">
    <div class="col-lg-12">
        <div>
            <p-table [styleClass]="'table'"
                     id="myTable"
                     #dt1
                     [value]="documents"
                     [paginator]="true"
                     [rows]="10"
                     [showCurrentPageReport]="true"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                     [rowsPerPageOptions]="[10, 25, 50]"
                     [globalFilterFields]="['libelle']"
            >
                <ng-template pTemplate="caption">
                    <div class="flex">
                        <span class="p-input-icon-left ml-auto">
                            <input type="text" placeholder="Recherche..."
                                   class="form-control"
                                   (input)="dt1.filterGlobal($event.target.value, 'contains')"/>
                        </span>

                        <button type="button" ngbtooltip="btn btn-square btn-info"
                                style="color: white; float: right;" (click)="creer()"
                                class="btn btn-info btn-sm">
                            Ajouter un document
                        </button>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="background: white">Type de document</th>
                        <th style="background: white">Libellé</th>
                        <th style="background: white">Date</th>
                        <th style="background: white">Actions</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td>{{ item?.type?.libelle }}</td>
                        <td>{{ item?.libelle }}</td>
                        <td>
                            {{ item?.date.day + '/' + item?.date.month + '/' + item?.date.year }}
                        </td>
                        <td>
                            <div class="action">
                                <li class="delete">
                                    <a href="javascript:void(0)" (click)="supprimer(item.id)">
                                        <i class="icon-trash"></i>
                                    </a>
                                </li>
                                <li class="success">
                                    <a href="javascript:void(0)" (click)="telecharger(item.id)">
                                        <i class="icon-download"></i>
                                    </a>
                                </li>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4">
                            <app-no-data></app-no-data>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
