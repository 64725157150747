import {Component, OnInit} from '@angular/core';
import {TreeNode} from 'primeng/api';
import {PostePermissionService} from '../poste-permission.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {TreeService} from '../tree.service';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})
export class PermissionComponent implements OnInit {
  permissions: TreeNode[] = [];
  selectedPermissions!: TreeNode[];

  constructor(
      private postePermissionService: PostePermissionService,
      private treeService: TreeService,
      private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.postePermissionService.data$.subscribe(data => {
      console.log(data);
      if (data != null) {
        this.listerPermissions();
      }
    });
  }

  private listerPermissions(): void {
    this.http.get(environment.url + 'permission')
        .subscribe(data => {
          this.permissions = this.treeService.permissionVersTreeNode(data);
        });
  }
}
