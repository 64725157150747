import {CommonModule, DecimalPipe} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import { DragulaModule } from "ng2-dragula";
import {TranslateModule} from '@ngx-translate/core';
// Components
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {CustomizerComponent} from './components/customizer/customizer.component';
import {FeatherIconsComponent} from './components/feather-icons/feather-icons.component';
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {ContentComponent} from './components/layout/content/content.component';
import {FullComponent} from './components/layout/full/full.component';
import {LoaderComponent} from './components/loader/loader.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {TapToTopComponent} from './components/tap-to-top/tap-to-top.component';
// Header Elements Components
import {SearchComponent} from './components/header/elements/search/search.component';
import {MegaMenuComponent} from './components/header/elements/mega-menu/mega-menu.component';
import {LanguagesComponent} from './components/header/elements/languages/languages.component';
import {NotificationComponent} from './components/header/elements/notification/notification.component';
import {BookmarkComponent} from './components/header/elements/bookmark/bookmark.component';
import {CartComponent} from './components/header/elements/cart/cart.component';
import {MessageBoxComponent} from './components/header/elements/message-box/message-box.component';
import {MyAccountComponent} from './components/header/elements/my-account/my-account.component';
// Directives
import {DisableKeyPressDirective} from './directives/disable-key-press.directive';
import {OnlyAlphabetsDirective} from './directives/only-alphabets.directive';
import {OnlyNumbersDirective} from './directives/only-numbers.directive';
import {ShowOptionsDirective} from './directives/show-options.directive';
// Services
import {ChatService} from './services/chat.service';
import {LayoutService} from './services/layout.service';
import {NavService} from './services/nav.service';
import {TableService} from './services/table.service';
import {NgbdSortableHeader} from './directives/NgbdSortableHeader';
import {SvgIconComponent} from './components/svg-icon/svg-icon.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {SwiperModule} from 'swiper/angular';
import {SwiperComponent} from './components/header/elements/swiper/swiper.component';
import {TableModule} from 'primeng/table';
import {NoDataComponent} from '../components/no-data/no-data.component';
import {FormViewDirective} from './form-view.directive';
import {DetailItemComponent} from '../components/detail-item/detail-item.component';
import {ActeDocumentComponent} from '../pages/acte/acte-document/acte-document.component';
import {InformationClientComponent} from '../pages/client/information-client/information-client.component';
import {FormulaireAssocieComponent} from '../pages/acte/formulaire-associe/formulaire-associe.component';
import { FormulaireAdministrationComponent } from '../pages/acte/formulaire-administration/formulaire-administration.component';
import {TabMenuModule} from 'primeng/tabmenu';
import { HistoriqueActeComponent } from '../pages/client/historique-acte/historique-acte.component';
import {NgDropzoneComponent} from '../components/ng-dropzone/ng-dropzone.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxDropzoneModule} from 'ngx-dropzone';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    FeatherIconsComponent,
    FullComponent,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    MegaMenuComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    NgbdSortableHeader,
    SvgIconComponent,
    SwiperComponent,
    NoDataComponent,
    FormViewDirective,
    DetailItemComponent,
    ActeDocumentComponent,
    InformationClientComponent,
    HistoriqueActeComponent,
    FormulaireAssocieComponent,
    FormulaireAdministrationComponent,
    NgDropzoneComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule.forRoot(),
    CarouselModule,
    SwiperModule,
    TableModule,
    TabMenuModule,
    NgSelectModule,
    NgxDropzoneModule
  ],
  providers: [NavService, ChatService, LayoutService, TableService, DecimalPipe],
  exports: [
    NgbModule, FormsModule, ReactiveFormsModule, TranslateModule, NgSelectModule, NgxDropzoneModule, TableModule,
    LoaderComponent, BreadcrumbComponent, FeatherIconsComponent, TapToTopComponent, DisableKeyPressDirective, OnlyAlphabetsDirective,
    OnlyNumbersDirective, NgbdSortableHeader, SvgIconComponent, SwiperModule, NoDataComponent, DetailItemComponent, ActeDocumentComponent,
    InformationClientComponent, InformationClientComponent, HistoriqueActeComponent, FormulaireAssocieComponent,
    FormulaireAdministrationComponent, NgDropzoneComponent
  ],
})
export class SharedModule {
}
