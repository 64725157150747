import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PostePermissionService {
  private dataSubject = new BehaviorSubject<any>(null);
  data$: Observable<string> = this.dataSubject.asObservable();

  updateData(newData: string): void {
    this.dataSubject.next(newData);
  }
}
