<div class="card" style="padding: 10px;">
    <div class="signal-table" style="padding: 10px;">
        <div class="row">
            <div class="col-md-8">
                <div>
                    <span style="color: #0ec4f7; font-size: 16px; font-weight: bold">
                        Permissions <br>
                    </span>
                    <span class="tw-text-xs tw-text-gray-500">
                        Cocher les permissions du groupe d'utilisateurs selectionné
                    </span>
                </div>
            </div>
            <div class="col-md-4">
                <button type="button" ngbtooltip="btn btn-square btn-info"
                        style="color: white; float: right;"
                        class="btn btn-info btn-sm">
                    Enregistrer
                </button>
            </div>
        </div>

        <div *ngIf="permissions.length; else emptyBlock">
            <p-tree [value]="permissions" selectionMode="checkbox" class="w-full md:w-30rem"
                    [(selection)]="selectedPermissions"></p-tree>
        </div>
        <ng-template #emptyBlock>
            <div>
                <app-no-data></app-no-data>
            </div>
        </ng-template>
    </div>
</div>
