import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {Utilitaire} from '../../../shared/Utilitaire';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-formulaire-utilisateur',
  templateUrl: './formulaire-utilisateur.component.html',
  styleUrls: ['./formulaire-utilisateur.component.scss']
})
export class FormulaireUtilisateurComponent implements OnInit {
  utilisateurForm!: FormGroup;
  poste$: any;
  photos: File[] = [];

  constructor(
      private fb: FormBuilder,
      private http: HttpClient,
      private toastr: ToastrService,
      private router: Router,
      private utilitaire: Utilitaire
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(data?: any): void {
    this.listerPoste();
    this.utilisateurForm = this.fb.group({
      nom: null,
      prenoms: null,
      email: null,
      contact1: null,
      contact2: null,
      profession: null,
      adresse: null,
      poste: null
    });
  }

  convertForm(): any {
    return this.utilisateurForm.getRawValue();
  }

  save(): void {
    this.http.post(environment.url + 'utilisateur', this.recupererFormData())
        .subscribe({
          next: () => {
            this.toastr.success('Utilisateur créé avec succès !', 'Utilisateur');
            this.router.navigate(['/utilisateur']);
          }
        });
  }

  private recupererFormData(): FormData {
    const formData = this.utilitaire.convertirVersFormData(this.convertForm());
    this.utilitaire.ajouterFichier(formData, 'photo', this.photos);
    return formData;
  }

  private listerPoste(): void {
    this.http.get(environment.url + 'poste')
        .subscribe((data: any) => {
          this.poste$ = data;
        });
  }
}
