<app-breadcrumb [title]="'Créer un utilisateur'" [items]="['Utilisateur']"
                [active_item]="'Créer un utilisateur'"></app-breadcrumb>
<div class="container-fluid basic_table">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <form class="needs-validation" (ngSubmit)="save()" [formGroup]="utilisateurForm">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label>
                                    Nom <code>(*)</code>
                                </label>
                                <input type="text" class="form-control" formControlName="nom"
                                       placeholder="Veuillez saisir le nom"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label>
                                    Adresse
                                </label>
                                <input type="text" class="form-control" formControlName="adresse"
                                       placeholder="Veuillez saisir l'adresse"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label>
                                    Prénoms <code>(*)</code>
                                </label>
                                <input type="text" class="form-control" formControlName="prenoms"
                                       placeholder="Veuillez saisir le prénom"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label>
                                    Contact
                                </label>
                                <input type="text" class="form-control" formControlName="contact2"
                                       placeholder="Veuillez saisir le contact"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label>
                                    Telephone <code>(*)</code>
                                </label>
                                <input type="text" class="form-control" formControlName="contact1"
                                       placeholder="Veuillez saisir le téléphone"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label>
                                    Profession
                                </label>
                                <input type="text" class="form-control" formControlName="profession"
                                       placeholder="Veuillez saisir la profession"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label>
                                    Email <code>(*)</code>
                                </label>
                                <input type="text" class="form-control" formControlName="email"
                                       placeholder="Veuillez saisir l'email"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label">
                                    Poste <code>(*)</code>
                                </label>
                                <ng-select [items]="poste$" bindLabel="libelle" bindValue="id"
                                           placeholder="Veuillez selectionner un poste"
                                           formControlName="poste"></ng-select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="mb-3">
                                <label> Photo </label>
                                <app-ng-dropzone placeholder="Veuillez selectionner une photo"
                                                 [files]="photos"></app-ng-dropzone>
                            </div>
                        </div>
                        <div style="text-align: right">
                            <button class="btn btn-success" type="submit">
                                Valider <i class="mdi mdi-check-circle"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
