<app-breadcrumb [title]="'Liste des utilisateurs'" [items]="['Utilisateur']"
                [active_item]="'Liste des utilisateurs'"></app-breadcrumb>
<div class="container-fluid basic_table">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="signal-table">
                    <p-table [styleClass]="'table table-hover'"
                             id="myTable"
                             #dt1
                             [value]="items$"
                             [paginator]="true"
                             [rows]="10"
                             [showCurrentPageReport]="true"
                             currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                             [rowsPerPageOptions]="[10, 25, 50]"
                             [globalFilterFields]="['nomComplet', 'email', 'contact1','poste']"
                    >
                        <ng-template pTemplate="caption">
                            <div class="flex">
                                <button type="button"
                                        style="height: 40px; border-radius: 50%; color: white"
                                        (click)="clear(dt1)" ngbtooltip="btn btn-square btn-info"
                                        class="btn btn-info btn-sm">
                                    <app-feather-icons [icon]="'refresh-cw'"></app-feather-icons>
                                </button>

                                <span class="p-input-icon-left ml-auto">
                                    <input type="text" placeholder="Recherche..."
                                           class="form-control"
                                           (input)="dt1.filterGlobal($event.target.value, 'contains')"/>
                                </span>

                                <button type="button" ngbtooltip="btn btn-square btn-info"
                                        style="color: white; float: right;"
                                        [routerLink]="['/utilisateur/form']"
                                        class="btn btn-info btn-sm">
                                    Ajouter un utilisateur
                                </button>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="background: white">Photo</th>
                                <th style="background: white">Nom complet</th>
                                <th style="background: white">Email</th>
                                <th style="background: white">Contact</th>
                                <th style="background: white">Poste</th>
                                <th style="background: white">Statut</th>
                                <th style="background: white">Actions</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr style="border-color: #dee2e6">
                                <td>
                                    <div class="media-size-email">
                                        <img [src]="item.photo" alt="" class="me-3 rounded-circle"
                                             style="height: 40px;"/>
                                    </div>
                                </td>
                                <td>{{ item.nomComplet }}</td>
                                <td>{{ item.email }}</td>
                                <td>{{ item.contact1 }}</td>
                                <td>{{ item.poste }}</td>
                                <td>
                                    <span class="badge bg-success" *ngIf="item.statut">
                                        Activer
                                    </span>
                                    <span class="badge bg-warning" *ngIf="!item.statut">
                                        Désactiver
                                    </span>
                                </td>
                                <td>
                                    <div class="action">
                                        <div class="col text-end">
                                            <div ngbDropdown class="dropdown">
                                                <button class="btn dropdown-toggle"
                                                        id="dropdownMenuButton"
                                                        ngbDropdownToggle>
                                                    Actions
                                                </button>
                                                <div ngbDropdownMenu
                                                     aria-labelledby="dropdownMenuButton"
                                                     class="email-option-position">
                                                    <a href="javascript:void(0)"
                                                       class="dropdown-item">
                                                        Consulter
                                                    </a>
                                                    <a href="javascript:void(0)"
                                                        (click)="activer(item)" *ngIf="!item.statut"
                                                       class="dropdown-item">
                                                        Activer
                                                    </a>
                                                    <a href="javascript:void(0)"
                                                       (click)="activer(item)" *ngIf="item.statut"
                                                       class="dropdown-item">
                                                        Désactiver
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="7">
                                    <app-no-data></app-no-data>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
