import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, UntypedFormBuilder} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-formulaire-ville',
  templateUrl: './formulaire-ville.component.html',
  styleUrls: ['./formulaire-ville.component.scss']
})
export class FormulaireVilleComponent implements OnInit {
  pays$: any = [];
  @Input() item: any;
  villeForm!: FormGroup;

  constructor(
      private fb: UntypedFormBuilder,
      private http: HttpClient,
      public activeModal: NgbActiveModal,
      private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.listerPays();
    this.initForm(this.item);
  }

  initForm(data?: any): void {
    this.villeForm = this.fb.group({
      pays: data?.paysId || null,
      libelle: data?.libelle || null,
      statut: data?.statut || null
    });
  }

  convertForm(): any {
    return this.villeForm.getRawValue();
  }

  close(): void {
    this.activeModal.close(false);
  }

  save(): void {
    if (this.item == null) {
      this.createRequest();
    } else {
      this.updateRequest();
    }
  }

  createRequest(): void {
    this.http.post(environment.url + 'ville', this.convertForm())
        .subscribe({
          next: () => {
            this.initForm();
            this.toastr.success('Ville créé avec succès !', 'Création de ville');
            this.activeModal.close(true);
          }
        });
  }

  updateRequest(): void {
    this.http.put(environment.url + 'ville/' + this.item.id, this.convertForm())
        .subscribe({
          next: () => {
            this.initForm();
            this.toastr.success('Ville modifiée avec succès !', 'Modification de villes');
            this.activeModal.close(true);
          }
        });
  }

  private listerPays(): void {
    this.http.get(environment.url + 'pays-actif')
        .subscribe(data => {
          this.pays$ = data;
        });
  }
}
