import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Utilitaire } from 'src/app/shared/Utilitaire';

@Component({
  selector: 'app-formulaire-administration',
  templateUrl: './formulaire-administration.component.html',
  styleUrls: ['./formulaire-administration.component.scss']
})
export class FormulaireAdministrationComponent implements OnInit {

  @Input() items: any = [];
  types$: any = [];
  type: any;
  precision: any;
  nomComplet: any;
  montant: any;

  constructor(
    private modalService: NgbModal,
    private utilitaire: Utilitaire
  ) {}
  
  ngOnInit(): void {
    this.listerUtilitaire();
  }

  listerUtilitaire() {
    this.types$ = this.utilitaire.typeMembre();
  }

  recupererType(type) {
   return this.types$.filter((item: any) => item.id == type)[0].libelle;
  }
  
  modalAjout(content) {
    this.initialisation(0, null, null, 0);
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  enregistrer(): void {
    if(this.type > 0 && this.nomComplet != null && this.montant > 0) {
      this.items.push({type: this.type, precision: this.precision, nomComplet: this.nomComplet, montant: this.montant});
      this.initialisation(0, null, null, 0);
    }
  }
  
  retirer(index: number): void {
    this.items.splice(index, 1);
  }
  
  initialisation(type: number, precision: any, nomComplet: any, montant: number) {
    this.type = type;
    this.precision = precision;
    this.nomComplet = nomComplet;
    this.montant = montant;
  }
}
