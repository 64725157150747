import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';

// import localeFr from '@angular/common/locales/fr';

// import { defineLocale } from 'ngx-bootstrap/chronos';
// import { frLocale } from 'ngx-bootstrap/locale';

// defineLocale('fr', frLocale);
// registerLocaleData(localeFr);

// // for HttpClient import:
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
// // for Router import:
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
// // for Core import:
import {LoadingBarModule} from '@ngx-loading-bar/core';

import {AdminGuard} from './shared/guard/admin.guard';
import {CookieService} from 'ngx-cookie-service';

import {AppComponent} from './app.component';
import {LoginComponent} from './auth/login/login.component';

import {OverlayModule} from '@angular/cdk/overlay';
import {JwtInterceptor} from './services/helpers/jwt.interceptor';
import {ErrorInterceptor} from './services/helpers/error.interceptor';
import {LoaderInterceptor} from './services/helpers/loader.interceptor';
import {TypePieceComponent} from './pages/type-piece/type-piece.component';
import {TableModule} from 'primeng/table';
import {PostePermissionComponent} from './pages/poste-permission/poste-permission.component';
import {PostePermissionModule} from './pages/poste-permission/poste-permission.module';
import {DatePipe, registerLocaleData} from '@angular/common';
import {DocumentClientComponent} from './pages/client/document-client/document-client.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {UtilisateurModule} from './pages/utilisateur/utilisateur.module';
import {FormulaireDocumentComponent} from './pages/acte/acte-document/formulaire-document/formulaire-document.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TypePieceComponent,
    PostePermissionComponent,
    DocumentClientComponent,
    FormulaireDocumentComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    OverlayModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),

    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    TableModule,
    PostePermissionModule,
    NgSelectModule,
    UtilisateurModule
  ],
  providers: [AdminGuard, CookieService, DatePipe,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
