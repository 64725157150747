import { Component } from '@angular/core';

@Component({
  selector: 'app-poste-permission',
  templateUrl: './poste-permission.component.html',
  styleUrls: ['./poste-permission.component.scss']
})
export class PostePermissionComponent {

}
