import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {AuthentificationService} from '../../../../../services/authentification.service';

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {

  constructor(public router: Router, public authService: AuthentificationService) {
  }

  ngOnInit(): void {}

  logoutFunc(): void {
    this.authService.logout();
    this.router.navigate(['auth/login']);
  }
}
