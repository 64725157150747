import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Table} from 'primeng/table';
import {environment} from '../../../environments/environment';
import {FormulaireVilleComponent} from '../ville/formulaire-ville/formulaire-ville.component';
import Swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-utilisateur',
  templateUrl: './utilisateur.component.html',
  styleUrls: ['./utilisateur.component.scss']
})
export class UtilisateurComponent implements OnInit {
  items$: any = [];

  constructor(
      private primengConfig: PrimeNGConfig,
      private http: HttpClient,
      private modalService: NgbModal,
      private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.listerUtilisateur();
  }

  clear(table: Table): void {
    table.clear();
  }

  private listerUtilisateur(): void {
    this.http.get(environment.url + 'utilisateur')
        .subscribe((data: any) => {
          this.items$ = data;
        });
  }

  creer(): void {
    const modalRef = this.modalService.open(FormulaireVilleComponent);
    modalRef.result.then((result) => {
      if (result) {
        this.listerUtilisateur();
      }
    });
  }

  activer(item: any): void {
    Swal.fire({
      title: 'Voulez vous vraiment effectuer cette action ?',
      text: 'Cette action est irréversible !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Oui !',
      cancelButtonText: 'Non !'
    }).then(result => {
      if (result.value) {
        this.http.post(environment.url + 'changer-statut-utilisateur/' + item.id, {})
            .subscribe({
              next: () => {
                this.toastr.success('Statut changé avec succès !', 'Changement de statut');
                this.listerUtilisateur();
              }
            });
      }
    });
  }
}
