import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-ng-dropzone',
  templateUrl: './ng-dropzone.component.html',
  styleUrls: ['./ng-dropzone.component.scss']
})
export class NgDropzoneComponent {

  @Input() placeholder = '';
  @Input() files: File[] = [];

  constructor() {
  }

  onSelect(event): void {
    if (this.files.length) {
      this.reset();
    }
    this.files.push(...event.addedFiles);
  }

  onRemove(event): void {
    this.files.splice(this.files.indexOf(event), 1);
  }

  reset(): void {
    this.files = [];
  }
}
