import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-formulaire-associe',
  templateUrl: './formulaire-associe.component.html',
  styleUrls: ['./formulaire-associe.component.scss']
})
export class FormulaireAssocieComponent {

  @Input() items: any = [];
  nomComplet: any;
  nombrePart: number;

  constructor(
    private modalService: NgbModal
  ) {}
  
  modalAjout(content) {
    this.initialisation(null, 0);
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  enregistrer(): void {
    if(this.nomComplet != null && this.nombrePart > 0) {
      this.items.push({nomCompletAssocie: this.nomComplet, nombreParts: this.nombrePart});
      this.initialisation(null, 0);
    }
  }
  
  retirerAssocier(index: number): void {
    this.items.splice(index, 1);
  }
  
  initialisation(nomComplet: any, nombrePart: number) {
    this.nomComplet = nomComplet;
    this.nombrePart = nombrePart;
  }
}
