import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PostePermissionRoutingModule} from './poste-permission-routing.module';
import {PosteComponent} from './poste/poste.component';
import {FormulairePosteComponent} from './Poste/formulaire-poste/formulaire-poste.component';
import {SharedModule} from '../../shared/shared.module';
import {TableModule} from 'primeng/table';
import { PermissionComponent } from './permission/permission.component';
import {TreeModule} from 'primeng/tree';


@NgModule({
  declarations: [
    PosteComponent,
    FormulairePosteComponent,
    PermissionComponent
  ],
  exports: [
    PosteComponent,
    PermissionComponent
  ],
  imports: [
    CommonModule,
    PostePermissionRoutingModule,
    SharedModule,
    TableModule,
    TreeModule
  ]
})
export class PostePermissionModule {
}
