<div class="col-md-12" style="margin-top: 20px;">

    <div class="card">
        <div class="signal-table">
            <p-table [styleClass]="'table'" id="myTable"
                     #dt1 dataKey="id" [value]="items"
            >
                <ng-template pTemplate="caption">
                    <div class="row">
                        <div class="flex">
                            <button type="button" ngbtooltip="btn btn-square btn-info"
                                    style="color: white; float: right;"
                                    (click)="modalAjout(content)" class="btn btn-info btn-sm">
                                Ajouter un membre
                            </button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="background: white">Type de membre</th>
                        <th style="background: white">Précision membre</th>
                        <th style="background: white">Nom complet</th>
                        <th style="background: white">Montant et pourcentage</th>
                        <th style="background: white">Actions</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-i="index">
                    <tr style="border-color: #dee2e6">
                        <td>{{ this.recupererType(item.type) }}</td>
                        <td>{{ item.precision }}</td>
                        <td>{{ item.nomComplet }}</td>
                        <td>{{ item.montant }}</td>
                        <td>
                            <div class="action" (click)="retirer(i)">
                                <li class="delete">
                                    <a style="cursor: pointer"> 
                                        <i class="icon-trash"></i>
                                    </a>
                                </li>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5">
                            <app-no-data></app-no-data>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Formulaire de gestion membre d'administration
      </h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="mb-3">
                    <label>
                        Type de membre <code>(*)</code>
                    </label>
                    <select class="form-control" [(ngModel)]="type">
                        <option *ngFor="let item of types$" [value]="item.id">{{ item.libelle }}</option>
                      </select>
                    <ng-select bindLabel="libelle" bindValue="id" 
                        placeholder="Veuillez selectionner un type de membre"></ng-select>
                </div>
            </div>
            <div class="col-md-12" *ngIf="type == 3">
                <div class="mb-3">
                    <label>
                        Précision du membre <code>(*)</code>
                    </label>
                    <input type="text" class="form-control" [(ngModel)]="precision"
                        placeholder="Veuillez saisir préciser le type de membre"/>
                </div>
            </div>
            <div class="col-md-12">
                <div class="mb-3">
                    <label>
                        Nom complet <code>(*)</code>
                    </label>
                    <input type="text" class="form-control" [(ngModel)]="nomComplet"
                        placeholder="Veuillez saisir le nom complet"/>
                </div>
            </div>
            <div class="col-md-12">
                <div class="mb-3">
                    <label>
                        Montant et pourcentage de l'apport <code>(*)</code>
                    </label>
                    <input type="text" class="form-control" [(ngModel)]="montant"
                        placeholder="Veuillez saisir le montant et pourcentage de l'apport"/>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-success" (click)="enregistrer()">Enregistrer</button>
    </div>
  </ng-template>