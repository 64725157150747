import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UtilisateurComponent} from './utilisateur.component';
import {FormulaireUtilisateurComponent} from './formulaire-utilisateur/formulaire-utilisateur.component';

const routes: Routes = [
  {path: '', component: UtilisateurComponent},
  {path: 'form', component: FormulaireUtilisateurComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UtilisateurRoutingModule {
}
