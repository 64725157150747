import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Utilitaire} from '../../../shared/Utilitaire';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class DocumentUtilitaire {

  constructor(
      protected fb: FormBuilder,
      private utilitaire: Utilitaire
  ) {
  }

  initialiserDocumentForm(data?: any): FormGroup {
    return this.fb.group({
      id: data?.id || 0,
      type: data?.type || null,
      libelle: data?.libelle || null,
      date: data?.date || null,
      pieceJointe: data?.pieceJointe || null,
    });
  }

  ajouter(documents, documentForm, document, toastr: ToastrService, pieceJointe: File[], acteId: any): boolean {
    if (document.typeDocument == null || document.libelleDocument == null || document.dateDocument == null) {
      toastr.error('Veuillez renseigner tous les champs !', 'Documents à fournir');
      return false;
    }

    if (acteId === undefined && pieceJointe.length === 0) {
      toastr.error('Veuillez renseigner tous les champs !', 'Documents à fournir');
      return false;
    }

    if (pieceJointe.length !== 0) {
      console.log(pieceJointe[0]);
      const reader = new FileReader();
      reader.readAsDataURL(pieceJointe[0]);
      reader.onload = () => {
        documents.push({
          id: document.id,
          typeId: document.typeDocument,
          type: document.typeDocument,
          libelle: document.libelleDocument,
          date: document.dateDocument,
          estNouveau: true,
          pieceJointe: reader.result,
          fichier: pieceJointe[0]
        });
      };
    } else {
      documents.push({
        id: document.id,
        type: document.typeDocument,
        libelle: document.libelleDocument,
        date: document.dateDocument,
        pieceJointe: null
      });
    }
    documentForm.reset();
  }

}
