<app-breadcrumb [title]="'Liste de type de pièces'" [items]="['Type de pièces']"
                [active_item]="'Liste de type de pièces'"></app-breadcrumb>
<div class="container-fluid basic_table">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="signal-table">
                    <p-table [styleClass]="'table table-hover'"
                             id="myTable"
                             #dt1
                             [value]="items$"
                             [paginator]="true"
                             [rows]="10"
                             [showCurrentPageReport]="true"
                             currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                             [rowsPerPageOptions]="[10, 25, 50]"
                             [globalFilterFields]="['libelle']"
                    >
                        <ng-template pTemplate="caption">
                            <div class="flex">
                                <button type="button"
                                        style="height: 40px; border-radius: 50%; color: white"
                                        (click)="clear(dt1)" ngbtooltip="btn btn-square btn-info"
                                        class="btn btn-info btn-sm">
                                    <app-feather-icons [icon]="'refresh-cw'"></app-feather-icons>
                                </button>

                                <span class="p-input-icon-left ml-auto">
                                    <input type="text" placeholder="Recherche..."
                                           class="form-control"
                                           (input)="dt1.filterGlobal($event.target.value, 'contains')"/>
                                </span>

                                <button type="button" ngbtooltip="btn btn-square btn-info"
                                        style="color: white; float: right;" (click)="creer()"
                                        class="btn btn-info btn-sm">
                                    Ajouter un type de pièce
                                </button>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="background: white">Libellé</th>
                                <th style="background: white">Statut</th>
                                <th style="background: white">Actions</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr style="border-color: #dee2e6">
                                <td>{{ item.libelle }}</td>
                                <td>
                                    <span class="badge bg-success" *ngIf="item.statut">
                                        Activer
                                    </span>
                                    <span class="badge bg-warning" *ngIf="!item.statut">
                                        Désactiver
                                    </span>
                                </td>
                                <td>
                                    <div class="action">
                                        <li class="edit">
                                            <a (click)="editer(item)">
                                                <i class="icon-pencil-alt"></i>
                                            </a>
                                        </li>
                                        <li class="delete">
                                            <a (click)="supprimer(item)">
                                                <i class="icon-trash"></i>
                                            </a>
                                        </li>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="4">
                                    <app-no-data></app-no-data>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
