import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DocumentUtilitaire} from '../../../client/document-client/DocumentUtilitaire';
import {Utilitaire} from '../../../../shared/Utilitaire';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-formulaire-document',
  templateUrl: './formulaire-document.component.html',
  styleUrls: ['./formulaire-document.component.scss']
})
export class FormulaireDocumentComponent implements OnInit {

  @Input() client: boolean;
  @Input() modelId: any;
  @Input() livre: boolean;

  documentForm!: FormGroup;
  typeDocument$: any;
  documentPieceJointe: File[] = [];

  constructor(
      private http: HttpClient,
      private documentUtilitaire: DocumentUtilitaire,
      private utilitaire: Utilitaire,
      public activeModal: NgbActiveModal,
      private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.listerTypeDocument();
    this.initForm();
  }

  initForm(): void {
    this.documentForm = this.documentUtilitaire.initialiserDocumentForm();
  }

  valider(): void {
    if (this.documentPieceJointe.length > 0) {
      this.enregistrer();
    }
  }

  private listerTypeDocument(): void {
    this.http.get(environment.url + 'type-piece-actif')
        .subscribe((data: any) => {
          this.typeDocument$ = data;
        });
  }

  private recupererFormData(): FormData {
    const formData = this.utilitaire.convertirVersFormData(this.documentForm.getRawValue());
    this.utilitaire.ajouterFichier(formData, 'pieceJointe', this.documentPieceJointe);
    this.utilitaire.createDate(formData, this.documentForm.get('date'), 'date');
    formData.append('modelId', this.modelId);
    formData.append('livre', this.livre == true ? '1' : '0');
    return formData;
  }

  private enregistrer(): void {
    if (this.client) {
      this.enregistrerDocument('client-document', 'Document du client', 'Document enregistré avec succès !');
    } else {
      this.enregistrerDocument('acte-document', 'Document d\'acte', 'Document enregistré avec succès !');
    }
  }

  private enregistrerDocument(url: string, titre: string, message: string): void {
    const formData = this.recupererFormData();
    this.http.post(environment.url + url, formData)
        .subscribe({
          next: () => {
            this.toastr.success(message, titre);
            this.activeModal.close(true);
          }
        });
  }
}
