import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, UntypedFormBuilder} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-formulaire-type-piece',
  templateUrl: './formulaire-type-piece.component.html',
  styleUrls: ['./formulaire-type-piece.component.scss']
})
export class FormulaireTypePieceComponent implements OnInit {
  @Input() item: any;
  typeForm!: FormGroup;

  constructor(
      private fb: UntypedFormBuilder,
      private http: HttpClient,
      public activeModal: NgbActiveModal,
      private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.initForm(this.item);
  }

  initForm(data?: any): void {
    this.typeForm = this.fb.group({
      libelle: data?.libelle || null,
      statut: data?.statut || null
    });
  }

  convertForm(): any {
    return this.typeForm.getRawValue();
  }

  close(): void {
    this.activeModal.close(false);
  }

  save(): void {
    if (this.item == null) {
      this.createRequest();
    } else {
      this.updateRequest();
    }
  }

  createRequest(): void {
    this.http.post(environment.url + 'type-piece', this.convertForm())
        .subscribe({
          next: () => {
            this.initForm();
            this.toastr.success('Type de pièce créé avec succès !', 'Création de type de pièce');
            this.activeModal.close(true);
          }
        });
  }

  updateRequest(): void {
    this.http.put(environment.url + 'type-piece/' + this.item.id, this.convertForm())
        .subscribe({
          next: () => {
            this.initForm();
            this.toastr.success('Type de pièce modifié avec succès !', 'Modification de type de pièce');
            this.activeModal.close(true);
          }
        });
  }
}
