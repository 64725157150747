import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Table} from 'primeng/table';
import {environment} from '../../../../environments/environment';
import {FormulairePosteComponent} from './formulaire-poste/formulaire-poste.component';
import {PostePermissionService} from '../poste-permission.service';

@Component({
  selector: 'app-poste',
  templateUrl: './poste.component.html',
  styleUrls: ['./poste.component.scss']
})
export class PosteComponent implements OnInit {
  items$: any = [];

  constructor(
      private primengConfig: PrimeNGConfig,
      private http: HttpClient,
      private modalService: NgbModal,
      private postePermissionService: PostePermissionService
  ) {
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.listerPoste();
  }

  clear(table: Table): void {
    table.clear();
  }

  private listerPoste(): void {
    this.http.get(environment.url + 'poste')
        .subscribe(data => {
          this.items$ = data;
        });
  }

  creer(): void {
    const modalRef = this.modalService.open(FormulairePosteComponent);
    modalRef.result.then((result) => {
      if (result) {
        this.listerPoste();
      }
    });
  }

  editer(item: any): void {
    const modalRef = this.modalService.open(FormulairePosteComponent);
    modalRef.componentInstance.item = item;
    modalRef.result.then((result) => {
      if (result) {
        this.listerPoste();
      }
    });
  }

  selectionner(item: any): void {
    this.postePermissionService.updateData(item.id);
  }
}
