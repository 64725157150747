import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-information-client',
  templateUrl: './information-client.component.html',
  styleUrls: ['./information-client.component.scss']
})
export class InformationClientComponent implements OnInit {

  @Input() public item;
  items: any = [];
  activeItem: any;

  ngOnInit(): void {
    this.items = [
      {id: 1, label: 'Informations personnelles', icon: 'icon-info-alt'},
      {id: 2, label: 'Documents du client', icon: 'icon-files'},
      {id: 3, label: 'Historiques des dossiers', icon: 'icon-folder'}
    ];

    this.activeItem = this.items[0];
  }

  onActiveItemChange($event: any): void {
    this.activeItem = this.items[$event?.id - 1];
  }
}
