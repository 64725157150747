import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthentificationService} from '../../services/authentification.service';
import {uses} from '../data/widget/chart';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard {
  constructor(public router: Router, private authService: AuthentificationService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.authService.currentUser.subscribe((user) => {
      if (!user) {
        this.router.navigate(['/auth/login']);
        return true;
      }
    });
    return true;
  }
}
