<app-breadcrumb [title]="'Gestion des postes et permissions'" [items]="['Postes', 'Postes et permissions']"
                [active_item]="'Gestion des postes et permissions'"></app-breadcrumb>
<div class="container-fluid basic_table">
    <div class="row">
        <div class="col-sm-6">
            <app-poste></app-poste>
        </div>
        <div class="col-sm-6">
            <app-permission></app-permission>
        </div>
    </div>
</div>
