import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthentificationService} from '../../services/authentification.service';
import {first} from 'rxjs';
import {LoaderService} from '../../services/helpers/loader.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public show = false;
  error = '';
  submitted = false;

  constructor(
      private fb: FormBuilder, public router: Router,
      private authenticationService: AuthentificationService
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  get f() {
    return this.loginForm.controls;
  }

  login(): void {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      this.authenticationService.login(this.f.email.value, this.f.password.value)
          .pipe(first())
          .subscribe(() => {
            this.router.navigate(['/tableau-bord']);
            },
              error => {
            this.error = error ? error : '';
          });
    }
  }

  showPassword(): void {
    this.show = !this.show;
  }
}
