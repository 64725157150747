<div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="signal-table">
                <p-table [styleClass]="'table'"
                         id="myTable"
                         #dt1
                         dataKey="id"
                         [value]="items$"
                         [paginator]="true"
                         [rows]="10"
                         [showCurrentPageReport]="true"
                         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                         [rowsPerPageOptions]="[10, 25, 50, 100]"
                         [globalFilterFields]="['intitule', 'numero', 'dateOuverture', 'statut']"
                         (onRowExpand)="onRowExpand($event)"
                >
                    <ng-template pTemplate="caption">
                        <div class="flex">
                            <span class="p-input-icon-left ml-auto">
                                <input type="text" placeholder="Recherche..." class="form-control"
                                       (input)="dt1.filterGlobal($event.target.value, 'contains')"/>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="background: white">Numéro</th>
                            <th style="background: white">Type dossier</th>
                            <th style="background: white">Intitulé</th>
                            <th style="background: white">Date ouverture</th>
                            <th style="background: white">Statut</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-expanded="expanded">
                        <tr style="border-color: #dee2e6">
                            <td>
                                <a href="javascript:void(0);" [pRowToggler]="item">
                                    <i [class]="expanded ?'icon-angle-down font-size-15' :
                                    'icon-angle-right font-size-15'"
                                       style="font-size: 14px !important;"></i>
                                </a>
                                {{ item.numero }}
                            </td>
                            <td>{{ item.typeDossier }}</td>
                            <td>{{ item.intitule }}</td>
                            <td>{{ item.dateOuverture }}</td>
                            <td>
                                <div [innerHTML]="item.statut"></div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="6">
                                <app-no-data></app-no-data>
                            </td>
                        </tr>
                    </ng-template>
                    <!--ng-template pTemplate="rowexpansion" let-item>
                        <tr>
                            <td colspan="6">
                                <app-information-acte-immobilier *ngIf="dossierType == 1" [item]="dossier"></app-information-acte-immobilier>
                                <app-information-constitution-societe-sa *ngIf="dossierType == 2 && nature == 1" [item]="dossier"></app-information-constitution-societe-sa>
                                <app-information-constitution-societe-sarl *ngIf="dossierType == 2 && nature == 2" [item]="dossier"></app-information-constitution-societe-sarl>
                            </td>
                        </tr>
                    </ng-template-->
                </p-table>
            </div>
        </div>
    </div>
</div>