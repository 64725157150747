<div class="row" style="background: #fafafa">
    <div class="col-md-12">
        <p-tabMenu [model]="items" [activeItem]="activeItem"
                   (activeItemChange)="onActiveItemChange($event)">
            <ng-template pTemplate="item" let-item>
                <a class="p-menuitem-link flex justify-content-between align-items-center p-3">
                    <div>
                        <i [class]="item.icon" style="font-size: 14px !important;"></i>
                        <span> {{ item.label }}</span>
                    </div>
                </a>
            </ng-template>
        </p-tabMenu>
    </div>

    <div class="row" *ngIf="activeItem?.id == 1" style="margin-top:10px">
        <div class="col-md-12" style="margin-top:10px" *ngIf="item?.image != null">
            <div style="font-weight: 700;"> Image</div>
            <div class="card-body avatar-showcase">
                <div class="avatars">
                    <div class="avatar ratio">
                        <img [src]="item?.image" [alt]="item?.nomComplet" class="b-r-8 img-100" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4" style="margin-top:10px" *ngIf="item?.situationMatrimoniale != null">
            <app-detail-item title="Situation matrimoniale"
                             [description]="item?.situationMatrimoniale"></app-detail-item>
        </div>
        <div class="col-md-4" style="margin-top:10px" *ngIf="item?.fixe != null">
            <app-detail-item title="Fixe" [description]="item?.fixe"></app-detail-item>
        </div>
        <div class="col-md-4" style="margin-top:10px" *ngIf="item?.profession != null">
            <app-detail-item title="Mandataire" [description]="item?.profession"></app-detail-item>
        </div>
        <div class="col-md-4" style="margin-top:10px" *ngIf="item?.lieuTravail != null">
            <app-detail-item title="Lieu de travail"
                             [description]="item?.lieuTravail"></app-detail-item>
        </div>
        <div class="col-md-4" style="margin-top:10px" *ngIf="item?.dateNaissance != null">
            <app-detail-item title="Date de naissance"
                             [description]="item?.dateNaissance"></app-detail-item>
        </div>
        <div class="col-md-4" style="margin-top:10px" *ngIf="item?.boitePostale != null">
            <app-detail-item title="Boite postale"
                             [description]="item?.boitePostale"></app-detail-item>
        </div>
        <div class="col-md-4" style="margin-top:10px" *ngIf="item?.representantLegal != null">
            <app-detail-item title="Représentant légal"
                             [description]="item?.representantLegal"></app-detail-item>
        </div>
        <div class="col-md-4" style="margin-top:10px" *ngIf="item?.rcc != null">
            <app-detail-item title="Numéro de régistre de commerce"
                             [description]="item?.rcc"></app-detail-item>
        </div>
        <div class="col-md-4" style="margin-top:10px" *ngIf="item?.adresse != null">
            <app-detail-item title="Siège sociale"
                             [description]="item?.adresse"></app-detail-item>
        </div>
        <div class="col-md-4" style="margin-top:10px" *ngIf="item?.sigle != null">
            <app-detail-item title="Sigle"
                             [description]="item?.sigle"></app-detail-item>
        </div>
        <div class="col-md-4" style="margin-top:10px" *ngIf="item?.email != null">
            <app-detail-item title="Adresse email"
                             [description]="item?.email"></app-detail-item>
        </div>
        <div class="col-md-4" style="margin-top:10px" *ngIf="item?.numeroDfe != null">
            <app-detail-item title="Numéro DFE"
                             [description]="item?.numeroDfe"></app-detail-item>
        </div>
    </div>
    <div class="col-md-12" *ngIf="activeItem?.id == 2" style="margin-top:10px">
        <app-acte-document [client]="true" [modelId]="item?.id"></app-acte-document>
    </div>
    <div class="col-md-12" *ngIf="activeItem?.id == 3" style="margin-top:10px">
        <app-historique-acte [clientId]="item.id"></app-historique-acte>
    </div>
</div>
