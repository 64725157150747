<app-breadcrumb [title]="'Formulaire de gestion des documents'" [items]="['Document des clients']"
                [active_item]="'Formulaire de gestion des documents'"></app-breadcrumb>
<div class="container-fluid basic_table">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <app-acte-document [client]="true" [modelId]="id"></app-acte-document>
                </div>
            </div>
        </div>
    </div>
</div>
