import {Injectable} from '@angular/core';
import {TreeNode} from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class TreeService {
  permissionVersTreeNode(data: any): TreeNode[] {
    return Object.keys(data).map(key => {
      const item = data[key];
      return {
        key: item.key,
        label: item.libelle,
        children: this.permissionVersTreeNode(item.enfants)
      };
    });
  }
}
