import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TableRowExpandEvent } from 'primeng/table';
import { hasUncaughtExceptionCaptureCallback } from 'process';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-historique-acte',
  templateUrl: './historique-acte.component.html',
  styleUrls: ['./historique-acte.component.scss']
})
export class HistoriqueActeComponent implements OnInit {

  @Input() clientId: number;
  items$: any = [];
  dossier: any;
  dossierType: number;
  nature: number;

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    this.listerDossier();
  }

  listerDossier() {
    this.http.get(environment.url + 'client-acte/' + this.clientId)
        .subscribe((data: any) => {
          this.items$ = data.data;
        });
  }

  onRowExpand(event: TableRowExpandEvent) {
    const dossierId = event?.data?.id;
    this.dossierType = Number(event?.data?.type);
    this.nature = Number(event?.data?.nature);
    this.recupererDossierParId(dossierId, this.dossierType, this.nature);
  }

  recupererDossierParId(dossierId: any, dossierType: number, nature: number) {
    switch(dossierType) {
      case 1:
        this.immobilier(dossierId);
        break;
      case 2:
        this.constitution(dossierId, nature);
        break;
      case 3:
        console.log("Number is 3");
        break;
      case 4:
        this.ouvertureCredit(dossierId);
        break;
      case 5:
        console.log("Number is 3");
        break;
      case 6:
         console.log("Number is 3");
        break;
      case 7:
        console.log("Number is 3");
        break;
      case 8:
        console.log("Number is 3");
        break;
      default:
        throw new DOMException();
    }
  }

  immobilier(dossierId: any) {
    this.lien('acte-immobilier/'+ dossierId);
  }
  
  ouvertureCredit(dossierId: any) {
    this.lien('acte-ouverture-credit/'+ dossierId);
  }
  
  constitution(dossierId: any, nature: number) {
    let lien: any;
    if(nature == 1) {
      lien = "constitution-societe-sa/"+ dossierId;
    }
    
    if(nature == 2) {
      lien = "constitution-societe-sarl/"+ dossierId;
    }
    
    if(nature == 3) {
      lien = "constitution-societe-gie/"+ dossierId;
    }
    
    if(nature == 4) {
      lien = "constitution-societe-sas/"+ dossierId;
    }
    
    if(nature == 5) {
      lien = "constitution-societe-sci/"+ dossierId;
    }
    
    if(nature == 6) {
      lien = "constitution-societe-individuel/"+ dossierId;
    }

    this.lien(lien);
  }

  public lien(lien: any) {
    this.http.get(environment.url + lien)
        .subscribe((data: any) => {
          this.dossier = data.data;
        });
  }
}
