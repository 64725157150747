import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PostePermissionComponent} from './poste-permission.component';

const routes: Routes = [
  {path: '', component: PostePermissionComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PostePermissionRoutingModule {
}
