import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {Table} from 'primeng/table';
import {environment} from '../../../environments/environment';
import Swal from 'sweetalert2';
import {FormulaireTypePieceComponent} from './formulaire-type-piece/formulaire-type-piece.component';

@Component({
  selector: 'app-type-piece',
  templateUrl: './type-piece.component.html',
  styleUrls: ['./type-piece.component.scss']
})
export class TypePieceComponent implements OnInit {
  items$: any = [];

  constructor(
      private primengConfig: PrimeNGConfig,
      private http: HttpClient,
      private modalService: NgbModal,
      private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.listerPays();
  }

  clear(table: Table): void {
    table.clear();
  }

  private listerPays(): void {
    this.http.get(environment.url + 'type-piece')
        .subscribe(data => {
          this.items$ = data;
        });
  }

  creer(): void {
    const modalRef = this.modalService.open(FormulaireTypePieceComponent);
    modalRef.result.then((result) => {
      if (result) {
        this.listerPays();
      }
    });
  }

  editer(item: any): void {
    const modalRef = this.modalService.open(FormulaireTypePieceComponent);
    modalRef.componentInstance.item = item;
    modalRef.result.then((result) => {
      if (result) {
        this.listerPays();
      }
    });
  }

  supprimer(item: any): void {
    Swal.fire({
      title: 'Voulez vous vraiment effectuer cette action ?',
      text: 'Cette action est irréversible !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Oui !',
      cancelButtonText: 'Non !'
    }).then(result => {
      if (result.value) {
        this.supprimerTypePiece(item.id);
      }
    });
  }

  private supprimerTypePiece(id): void {
    this.http.delete(environment.url + 'type-piece/' + id)
        .subscribe({
          next: () => {
            this.toastr.success('Type de pièce supprimé avec succès !', 'Suppression de type de pièce');
            this.listerPays();
          }
        });
  }
}
