import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-document-client',
  templateUrl: './document-client.component.html',
  styleUrls: ['./document-client.component.scss']
})
export class DocumentClientComponent {
  public id = undefined;

  constructor(private route: ActivatedRoute) {
    this.id = this.route.snapshot.params.id;
  }
}
