import {AfterViewInit, Directive, Injector, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';

@Directive({
  selector: '[appFormViewDirective]'
})
export class FormViewDirective implements OnInit, AfterViewInit, OnDestroy {
  protected listUrl: string;
  protected urlParamId = 'id';
  protected urlParams: { [key: string]: any };

  protected partials: any[];

  protected subscriptions: Subscription[] = [];

  formGroup: FormGroup = new FormGroup({});

  protected activatedRoute: ActivatedRoute;
  protected router: Router;
  protected fb: FormBuilder;
  protected http: HttpClient;

  constructor(
      protected injector: Injector,
  ) {
    this.activatedRoute = this.injector.get(ActivatedRoute);
    this.router = this.injector.get(Router);
    this.fb = this.injector.get(FormBuilder);
    this.http = this.injector.get(HttpClient);
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(data?: any): void {}

  /**
   * Convert form in correct format
   */
  convertForm(): any {
    return {...this.formGroup.getRawValue()};
  }

  /**
   * Call in sub classes for update partials
   * @protected
   */
  protected updatePartials(): void {}

  ngAfterViewInit(): void {
    this.updatePartials();
    this.updateForm();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(item => item.unsubscribe());
  }

  getItemRequest(): void {
  }

  private updateForm(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.urlParams = params;
      if (!params) {
        return;
      }

      const paramKeys = Object.keys(this.urlParams);

      for (const paramKey of paramKeys) {
        if (!this.urlParams[paramKey]) {
          return;
        }
      }

      this.getItemRequest();
    });
  }

  createRequest(): void {
  }

  updateRequest(): void {
  }

  getIsDialog(): boolean {
    return false;
  }

  save(): void {
    if (this.urlParams[this.urlParamId]) {
      this.updateRequest();
    } else {
      this.createRequest();
    }
  }

  /**
   * Retourne à la liste
   */
  gotoList(): void {
    if (this.getIsDialog()) {
      this.closeDialog();
      return;
    }
    if (!this.listUrl) {
      return;
    }

    this.router.navigate([this.listUrl]);
  }

  cancel(): void {
    this.gotoList();
  }

  closeDialog(): void {}

}
